import React from "react"
import { graphql } from "gatsby"
import { SiteMetadata, GalleryCarousel } from "../components"
import { WavesSVG, PasekaSVG, ZigZag } from '../components/SVG/'
import { Layout } from "../layouts/Layout"
import Img from "gatsby-image"

import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

import {Container, Row, Col, Carousel} from "react-bootstrap"

export default ({ data }) => {
    return (
      <Layout noWaveFooter>
        <SiteMetadata 
            title="Экскурсия на пасеку" 
            canonical="/paseka/"
            description="Пасека наша необыкновенная, экскурсия познавательная, люди добрые, а пчелы вообще просто чудо." 
        />          
        <Container className="mt-5">
            <Row>
                <Col lg={8} className="offset-lg-2 text-center">
                    <h1 className="d-inline-block mb-4 beeright-small">
                        Экскурсии на пасеку
                    </h1>
                    <p>
                    Окунитесь в мир пчёл и узнайте много интересных фактов! 
                    Так же вас ждёт мастер-класс по изготовлению свечей из вощины, дегустация мёда и шоколада ручной работы, и других продуктов пчеловодства у самовара с чаем.
                    </p>                    
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item my-5"
                         allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                         src="https://www.youtube.com/embed/dJBFDp1KoBM" 
                         title="Экскурсии на пасеку"
                         allowFullScreen></iframe>
                    </div>
                </Col>
            </Row>
        </Container>
        <WavesSVG up blue/> 
        <Container fluid className="blue position-relative">
            <Container>
                <Row>
                  <Col lg={8} className="text-center offset-lg-2 mb-4 pt-5">
                    <h2 className="d-inline-block my-4">Описание экскурсии</h2>
                    <p>
                      Пасека наша необыкновенная, экскурсия познавательная, люди добрые, а пчелы вообще просто чудо. 
                      Мы думаем о вас и стараемся каждый раз выложится на полную. 
                      Костюмы и хорошее настроение предоставляем.                      
                    </p> 
                    <p>
                      Экскурсия длится 2-2.5 часа, за это время мы с Вами успеем совершить:
                    </p>
                  </Col>                    
                </Row>
                <Row className="mt-5 text-center services-svg">
                  <Col xs={6} lg className="d-flex align-content-center mb-5">
                      <Row className="d-flex flex-column">  
                        <PasekaServices svg="farmer" text="Ритуал посвящения в 'Пчелиное Государство'" />
                        <PasekaServices svg="bee" text="Экскурсия к пчелкам с опытным пчеловодом. Знакомство с ульем и его жителями" />
                        <PasekaServices svg="footprint" text="Прогулка по сенсорной тропе" /> 
                      </Row>                
                  </Col>
                  <Col  xs={6} lg className="d-flex align-content-center mb-5 order-lg-last">                    
                      <Row className="d-flex flex-column">     
                        <PasekaServices svg="terem" text="Посещение Апидомика" />
                        <PasekaServices svg="honey" text="Чаепитие у самовара с дегустацией всей продукции пчеловодства" />
                        <PasekaServices svg="candle" text="Мастер-классы по изготовлению свечей и шоколада на меду" />     
                      </Row>                
                  </Col>
                  <Col  sm={12} lg={5} className="d-flex align-items-end justify-content-center">
                      <Img fluid={data.mordovnik.childImageSharp.fluid} alt="мордовник" className="w-75 w-md-100"/>
                  </Col>
                </Row>
            </Container>  
                  
        </Container>
        <Container fluid className="blue p-0">
          <WavesSVG up2 white className="position-relative mt_1 w-100"/>
        </Container>
        <Container>
          <Row>
            <Col lg={8} className="text-center offset-lg-2 mb-5">
              <h2 className="d-inline-block my-4">Отдых с семьей и близкими!</h2>
              <p>
                Мы всегда идем вам навстречу, и готовы подобрать экскурсию для Вас.
                Чтобы вам было легче ориентироваться, можете ознакомиться с возможными тарифами.                     
              </p> 
              <p>
                Вы можете позвонить и мы составим индивидуальное расписание для Вас!
              </p>
            </Col> 
          </Row>
          <Row>
            <Col lg={{span: 10, offset: 1}}>
              <PasekaTariffsCarousel />
            </Col>
          </Row>
        </Container>
        <WavesSVG up blue/> 
        <Container fluid className="blue py-5">
          <Container>
            <Row>
              <Col lg={{span: 10, offset: 1}} className="text-center">
                <h3 className="d-inline-block my-4">Душевные истории и особая атмосфера!</h3>
                <p className="mb-5">
                Самое дорогое для нас - видеть ваши счастливые глаза и хотя бы на день дать вам прочувствовать ту особенную атмосферу царящую на пасеке. 
                Пчелиный хуторок - это место, где вы почувствуете единение с природой и подружитесь с нашими полосатыми труженицами!
                </p>
                <GalleryCarousel images={data.gallery.edges} alt="Экскурсионная пасека"/>                
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    )
  }

  const PasekaServices = ({svg, text}) => {
    return (
      <Col className="mb-3">
        <div className="mb-3"><PasekaSVG type={svg}/></div>
        <div>{text}</div>
      </Col>
    )      
  }
  const PasekaTariffsCarousel = () =>{
    const cards = [
      {
      svg: "family",
      title : "Семейный",
      text : ["От 3-5 человек", 
      "2.5 часа экскурсии",   
      "10 минут посещения апидомика",  
      "Дегустация продуктов пчеловодства", 
      "Мастер-класс на выбор"],
      price : "от 1000 руб"
      },
      {
        svg : "friends",
        title : "Друзья", 
        text : ["От 5-10 человек", 
        "2.5 часа экскурсии",   
        "15 минут посещения апидомика",  
        "Дегустация продуктов пчеловодства", 
        "Мастер-класс на выбор"],
        price : "от 1500 руб"
      },
      {
        svg : "corporate",
        title : "Корпоратив",
        text : ["От 10 человек", 
        "2.5 часа экскурсии",   
        "20 минут посещения апидомика",  
        "Дегустация продуктов пчеловодства", 
        "Мастер-классы по свечам и шоколаду"],
        price : "от 3000 руб"
      }
    ]
    let winWidth = typeof window !== 'undefined' ? window.innerWidth : 1200
    if (winWidth >= 768)  return (
        <div className="card-deck row-cols-3">
          {cards.map((item, i) => (
                  <PasekaTariffs item={`tariff_${i}`} svg={item.svg} title={item.title} text={item.text} price={item.price}/>
            ))}
        </div>
        )
    else return (
      <Carousel 
            interval={null} 
            prevIcon={<FaChevronLeft/>}
            nextIcon={<FaChevronRight/>}
          >            
            {cards.map((item, i) => (
                <Carousel.Item key={`carousel_${i}`} className="px-4">
                  <PasekaTariffs svg={item.svg} title={item.title} text={item.text} price={item.price}/>
                </Carousel.Item>
            ))}
          </Carousel> 
    )
  }
  const PasekaTariffs = ({svg, title, text, price}) => {
    return (
      <div className="card text-center mx-md-4 my-4" style={{
          background: '#dfdfdf',
          borderRadius: '3rem',
          border: 'none',
          boxShadow: '3px 3px 3px #b9b7b7'
      }}>
        <div className="card-body">
          <div className="my-2 services-svg"><PasekaSVG type={svg}/></div>
          <h2 className="card-title mb-1 mt-3">{title}</h2>    
          <div className="px-lg-4"><ZigZag blue/></div> 
          <div className="card-text mt-4">{text.map((item, i) => <p key={`item_${i}`}>{item}</p>)}</div>          
        </div>
        <div className="card-footer mb-5" style={{fontSize: "1.5rem"}}>{price}</div>        
      </div>
    )
  }

  export const query = graphql`
    query PasekaQuery {
      mordovnik: file(relativePath: {eq: "images/mordovnik.png"}){
        childImageSharp {
          fluid(maxWidth: 445) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gallery: allFile(filter: {dir: {regex: "/data/images/paseka/"}}) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `